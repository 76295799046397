/* SP */

@media (max-width: 640px) {
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* container */
  .container-common-border {
    border: 1px solid black;
    border-radius: 12px;
    padding: 16px;
  }

  .container-common-border-smooth {
    border: 1px solid black;
    border-radius: 24px;
    padding: 36px 24px;
    margin: 24px 12px;
  }

  .main-container {
    margin-left: 8px;
    margin-right: 8px;
  }

  /* content layout */
  .content-board {
    padding: 64px 0 40px 0;
    background: rgb(242, 238, 232);
  }

  .content-board-title {
    padding-left: 8px;
    border-left: solid 3px rgb(0, 79, 138);
  }

  .content-board-content {
    padding: 16px;
    margin-left: 8px;
    margin-right: 8px;
    background: white;
  }

  .content-board-content-smooth {
    border-radius: 24px;
    padding: 30px 22px;
    margin-left: 8px;
    margin-right: 8px;
    background: white;
  }
  .row-half {
    display: inline-block;
    width: 45%;
  }
  .align-right {
    float: right;
  }

  .border-line {
    display: line;
    border-top: 1px solid black;
  }

  .border-line-black {
    border: 1px solid black;
  }

  .border-line-blue {
    border: 1px solid #004f8a;
    border-radius: 4px;
  }

  .required-label {
    border-radius: 40px;
    background: rgb(0, 190, 190);
    color: white;
    margin-left: 8px;
    display: inline-block;
    width: 40px;
    height: 16px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    padding: 2px 4px;
    line-height: 16px;
  }
  /* modal */
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 24px;
  }

  /* drop-down */
  .base-drop-down {
    border: 1px solid #004f8a;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    height: 3em;
    font-size: 17px;
    padding-left: 1em;
  }

  .drop-down-arrow {
    content: "";
    width: 5px;
    height: 5px;
    border-top: 2px solid black;
    border-right: 2px solid black;
    display: inline-block;
    transform: rotate(135deg);
    margin-left: -18px;
  }

  /* button */
  button {
    min-width: 200px;
  }

  .button-common-blue {
    background: rgb(68, 168, 255);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 240px;
    height: 48px;
    font-size: 16px;
    cursor: pointer;
  }
  .button-application-orange-disable,
  .button-application-orange,
  .button-confirm-orange-disable,
  .button-confirm-orange {
    background: #ff4e00;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: block;
    width: 80%;
    height: 60px;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
  }

  .button-application-orange {
    min-width: 300px;
  }

  .button-application-orange > div,
  .button-confirm-orange > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4%;
  }

  .button-confirm-orange-disable,
  .button-application-orange-disable {
    background: gray;
  }

  .button-arrow {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 3px solid white;
    border-right: 3px solid white;
    display: inline-block;
    transform: rotate(45deg);
  }

  .button-history-back {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: none;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: underline;
    min-width: 20px !important;
  }
  .board-button-two {
    width: 90%;
    margin: 0px auto;
    margin-top: 40px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  /* radio */
  .base-radio {
    width: 100%;
    display: block;
    vertical-align: middle;
    padding: 10px;
  }

  .coverage-type-label,
  .base-radio-label {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    border: solid 1px #004f8a;
    min-width: 123px;
  }

  .coverage-type-label-active,
  .base-radio-label-active {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fff;
    border: solid 1px #ff4e00;
    border-radius: 4px;
    min-width: 123px;
  }

  .coverage-type-label {
    border: 1px solid #004f8a;
    border-radius: 4px;
  }

  .coverage-type-label-active {
    border: 1px solid #004f8a;
    color: #ff4300;
  }

  .radio-button {
    text-align: center;
    background: white;
    height: 50px;
    width: 100%;
    border: 1px solid #004f8a;
    border-radius: 4px;
    margin: 4px 0;
    padding-left: 16px;
  }

  .radio-button-active {
    text-align: center;
    background: white;
    height: 50px;
    width: 100%;
    border: 1px solid #ff4e00;
    border-radius: 4px;
    margin: 4px 0;
    padding-left: 16px;
  }

  .radio-button-check {
    border: 1px solid #8a8c93;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .radio-button-check-active {
    border: 1px solid #8a8c93;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .radio-button-check-item {
    border: 1px solid rgb(255, 128, 0);
    border-radius: 50%;
    width: 9px;
    height: 9px;
    vertical-align: middle;
    display: revert;
    margin-left: 2px;
    margin-top: 2px;
    background: rgb(255, 128, 0);
  }

  .radio-button-text {
    padding-left: 8px;
    font-size: 18px;
    font-weight: normal;
  }

  /* img */
  .img-block {
    padding: 5px;
  }

  .insurance-target {
    width: 100%;
  }

  .group-discount {
    width: 100%;
  }

  /* header */
  .header-top-border {
    border-top: 8px solid rgb(0, 79, 138);
  }

  .header-bottom-border {
    border-bottom: 1px solid rgb(0, 79, 138);
  }
  .header-triangle {
    text-align: center;
    margin-top: -9.5px;
  }

  .header-triangle > div {
    width: 0;
    height: 0;
    display: inline-block;
    border-right: 6px solid transparent;
    border-top: 8px solid #004f8a;
    border-left: 6px solid transparent;
  }
  .line-yellow {
    background: linear-gradient(to top, #efff60 25%, transparent 50%);
  }

  .header-border {
    margin-top: 4px;
    border-bottom: 1px solid #004f8a;
  }

  /* footer */

  footer {
    border-top: 1px solid rgb(0, 79, 138);
  }

  .footer-margin {
    margin-top: 50px;
  }

  .registration-number {
    text-align: right;
  }
  /*occupation*/
  .occupation-list {
    border: 4px solid rgb(0, 79, 138);
    padding: 16px;
    background: rgb(242, 238, 232);
  }

  .occupation-list-header {
    font-size: 20px;
    color: #453c3d;
    font-weight: normal;
    display: block;
  }

  .occupation-list-item {
    display: inline-block;
    line-height: 30px;
    padding: 4px;
    text-align: left;
    border: 1px solid #8a8c93;
    border-radius: 16px;
    background: white;
    color: rgb(0, 79, 138);
  }
  .occupation-list-item-margin {
    display: inline-block;
    margin: 4px;
  }
  .text-align-center-mobile {
    text-align: center;
  }

  .drop-down-birth {
    vertical-align: top;
    display: inline-block;
    width: 25%;
    margin: 0px 4px;
  }

  .drop-down-birth:first-child {
    width: 38%;
  }

  /* plan */
  .plan-board,
  .plan-board-active {
    border: 1px solid #453c3d;
    border-radius: 4px;
  }

  .plan-board-active {
    border: 1px solid #ff4e00;
  }

  .plan-board-cover {
    margin: 0px 8px;
  }

  .plan-board-info {
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }

  .plan-board-info > div:first-child {
    display: flex;
    align-items: center;
  }

  .plan-title-info {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
    max-height: 30px;
    margin-bottom: 8px;
  }

  .plan-board-amount {
    margin-top: 1.8em;
    border: 2px solid rgba(255, 78, 0, 1);
    border-radius: 5px;
    padding: 0.8em;
  }

  .plan-board-amount > .plan-board-amount-title {
    background-color: rgba(255, 78, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    padding: 0.5em 1em 0em 1em;
    width: fit-content;
    position: relative;
    margin: 0px auto;
    top: -2.3em;
  }

  .plan-board-amount-triangle {
    width: 0;
    height: 0;
    display: block;
    position: relative;
    top: 0.7em;
    left: 4em;
    border-right: 15px solid transparent;
    border-top: 10px solid rgba(255, 78, 0, 1);
    border-left: 15px solid transparent;
  }

  .plan-board-amount > .plan-board-amount-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
  }

  .plan-board-detail {
    padding: 0px 32px 40px 32px;
    background-color: white;
    color: black;
    border-radius: 4px;
  }
  .plan-board-detail-price {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
  }

  .plan-board-detail-border {
    padding: 13px 16px 21px 16px;
  }

  .plan-board-detail-price > div:first-child {
    flex: 1;
  }

  .plan-board-detail-price > div:last-child {
    max-width: 50%;
    text-align: right;
  }

  .plan-board-detail-price > div:last-child > span:first-child {
    color: #004f8a;
  }

  .plan-board-content {
    display: none;
  }

  .plan-board-option {
    background-color: #e2f1fb;
  }

  .plan-board-option > div:last-child {
    padding: 12px;
  }

  .plan-board-option > div:first-child {
    background-color: #44a8ff;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    padding: 10px;
  }

  .plan-board-option > div:first-child::before {
    content: "+";
    font-size: 22px;
    margin-right: 2px;
  }

  .plan-option-check-box {
    display: inline-block;
    border: 2px solid #44a8ff;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    background-color: #fff;
  }

  .plan-option-price {
    display: flex;
  }

  .plan-option-price > div:last-child {
    margin-left: 10px;
    border: 1px solid #453c3d;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 15px;
    flex: 1;
    max-width: 300px;
    font-size: 13px;
    font-weight: bold;
    color: #453c3d;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plan-option-price > div:last-child > span:last-child {
    color: #004f8a;
  }

  .plan-background-blue {
    background: #6200ee;
  }
  .plan-background-orange {
    background: #ff8b8b;
  }
  .plan-background-green {
    background: #6faf7c;
  }
  .two-cols {
    display: flex;
    justify-content: space-between;
  }

  .two-cols > label {
    flex: 1;
  }
  .two-cols > label:first-child {
    margin-right: 8px;
  }

  .board-link {
    text-align: center;
    justify-content: center;
  }

  .background-bage {
    background-color: #f6f6f6;
  }

  .warning {
    color: #8a1b1f;
    font-size: 14px;
    font-weight: normal;
    display: flex;
  }

  .warning > span:first-child {
    margin-right: 3px;
  }

  .base-input-text-width-full,
  .base-input-text-long,
  .base-input-text {
    border: 1px solid #004f8a;
    border-radius: 4px;
    padding-left: 1em;
    height: 3em;
    font-size: 17px;
    max-width: calc(180px - 1em);
  }

  .base-input-text-long {
    max-width: 340px;
    width: 50vw;
  }

  .base-input-text:focus {
    border: solid 1px #ff4e00;
    border-radius: 8px;
  }

  .base-input-text-width-full {
    max-width: 360px;
    width: calc(100% - 1em);
  }

  .input-items-two-cols,
  .input-text-two-cols {
    display: flex;
    justify-content: space-between;
    margin: 8px auto;
  }

  .input-text-two-cols > span {
    display: flex;
    align-items: center;
    flex: 2;
  }

  .input-items-two-cols > div:first-child {
    margin-right: 8px;
  }

  .input-items-two-cols > div {
    flex: 1;
  }
  .input-text-two-cols > .base-input-area {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .base-input-area > div {
    color: red;
    font-size: small;
  }

  .input-text-width-95 > span > input {
    width: 95%;
  }

  .board-info-text {
    padding: 32px 40px;
    display: flex;
    align-items: flex-start;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .board-info-text > img {
    margin-right: 5px;
  }

  .board-info-text-with-subTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    opacity: 1;
  }

  .board-info-text-with-subTitle > div {
    padding: 0px;
    margin-bottom: 0px;
  }

  .board-info-text-with-subTitle > h4 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 16px;
  }
  .navigator-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .navigator-page {
    white-space: nowrap;
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    text-align: right;
  }

  .navigator-item {
    height: 24px;
    line-height: 24px;
    padding: 0px 4px 0px 5px;
    display: inline-block;
  }

  .navigator-item-triangle-gray,
  .navigator-item-triangle-blue,
  .navigator-item-triangle {
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 12px solid transparent;
    border-left: 5px solid rgba(255, 136, 0, 1);
    border-bottom: 12px solid transparent;
    margin-right: 4px;
    margin-bottom: -6px;
  }

  .navigator-item-orange {
    background-color: rgba(255, 136, 0, 1);
    padding: 0px 4px;
  }

  .navigator-item-gray {
    background-color: rgba(138, 140, 147, 1);
  }

  .navigator-item-blue {
    background-color: rgba(46, 137, 217, 1);
  }

  .navigator-item-triangle-gray {
    border-left: 5px solid rgba(138, 140, 147, 1);
  }

  .navigator-item-triangle-blue {
    border-left: 5px solid rgba(46, 137, 217, 1);
  }

  .board-table {
    margin-left: 29px;
  }

  .icon-info {
    width: 24px;
    height: 24px;
  }

  .two-col-item-width {
    width: calc(100% - 1em - 4px);
    max-width: calc(100% - 1em - 4px);
  }

  .mobile-align-center {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .drop-down-card {
    vertical-align: top;
    display: inline-block;
    width: 7em;
    margin: 0px 4px;
  }

  .drop-down-card:first-child {
    width: 4em;
  }
}

/* PC */

@media (min-width: 640px) {
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* container */
  .container-common-border {
    border: 1px solid black;
    border-radius: 12px;
    padding: 36px;
    max-width: 640px;
  }

  .container-common-border-smooth {
    border: 1px solid black;
    border-radius: 24px;
    padding: 36px 24px;
    margin: 24px 12px;
  }

  .main-container {
    padding-left: 8px;
    padding-right: 8px;
  }

  /* content layout */
  .content-board {
    padding: 40px 0 40px 0;
    background: rgb(242, 238, 232);
  }

  .content-board-title {
    padding-left: 8px;
    border-left: solid 3px rgb(0, 79, 138);
  }

  .content-board-content {
    padding: 36px;
    margin-left: 8px;
    margin-right: 8px;
    background: white;
    max-width: 640px;
  }

  .content-board-content-smooth {
    border-radius: 24px;
    padding: 30px 22px;
    margin-left: 8px;
    margin-right: 8px;
    background: white;
  }
  .row-half {
    display: inline-block;
    width: 45%;
  }
  .align-right {
    float: right;
  }
  .border-line {
    display: line;
    border-top: 1px solid black;
  }
  .border-line-black {
    border: 1px solid black;
  }

  .border-line-blue {
    border: 1px solid #004f8a;
    border-radius: 4px;
  }

  .required-label {
    border-radius: 40px;
    background: rgb(0, 190, 190);
    color: white;
    margin-left: 8px;
    display: inline-block;
    width: 40px;
    height: 16px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    padding: 2px 4px;
    line-height: 16px;
  }
  /* modal */
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 24px;
  }

  /* drop-down */
  .base-drop-down {
    border: 1px solid #004f8a;
    border-radius: 4px;
    background: #fff;
    width: 100%;
    height: 3em;
    font-size: 17px;
    padding-left: 1em;
  }

  .drop-down-arrow {
    content: "";
    width: 5px;
    height: 5px;
    border-top: 2px solid black;
    border-right: 2px solid black;
    display: inline-block;
    transform: rotate(135deg);
    margin-left: -18px;
  }

  /* button */
  button {
    min-width: 200px;
  }

  .button-common-blue {
    background: rgb(68, 168, 255);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 240px;
    height: 48px;
    font-size: 16px;
    cursor: pointer;
  }

  .button-application-orange-disable,
  .button-application-orange,
  .button-confirm-orange-disable,
  .button-confirm-orange {
    background: #ff4e00;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 30%;
    height: 60px;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    min-width: 300px;
  }

  .button-confirm-orange-disable,
  .button-application-orange-disable {
    background-color: gray;
  }
  .button-application-orange > div,
  .button-confirm-orange > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4%;
  }

  .button-arrow {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 3px solid white;
    border-right: 3px solid white;
    display: inline-block;
    transform: rotate(45deg);
  }

  .button-history-back {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: none;
    padding: 8px 16px 8px 16px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-decoration: underline;
    min-width: 20px !important;
  }

  .board-button-two {
    margin-top: 40px;
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .board-button-two {
    display: flex;
  }

  .board-button-two > div:last-child {
    margin-right: 5%;
  }

  .button-modal {
    width: 280px;
  }

  /* radio */
  .base-radio {
    width: 100%;
    display: block;
    vertical-align: middle;
    padding: 10px;
  }

  .coverage-type-label,
  .base-radio-label {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px #004f8a;
    background: #fff;
  }

  .coverage-type-label-active,
  .base-radio-label-active {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #fff;
    border: solid 1px #ff4e00;
    border-radius: 4px;
  }

  .coverage-type-label {
    border: 1px solid #004f8a;
    border-radius: 4px;
  }

  .coverage-type-label-active {
    border: 1px solid #004f8a;
    color: #ff4300;
  }

  .radio-button {
    text-align: center;
    background: white;
    height: 50px;
    width: 100%;
    border: solid 1px #004f8a;
    border-radius: 4px;
    margin: 4px 0;
    padding-left: 16px;
    max-width: 330px;
  }

  .radio-button-active {
    text-align: center;
    background: white;
    height: 50px;
    width: 100%;
    border: solid 1px #ff4e00;
    border-radius: 4px;
    margin: 4px 0;
    padding-left: 16px;
    max-width: 330px;
  }

  .radio-button-check {
    border: 1px solid gray;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .radio-button-check-active {
    border: 1px solid gray;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .radio-button-check-item {
    border: 1px solid rgb(255, 128, 0);
    border-radius: 50%;
    width: 9px;
    height: 9px;
    vertical-align: middle;
    display: revert;
    margin-left: 2px;
    margin-top: 2px;
    background: rgb(255, 128, 0);
  }

  .radio-button-text {
    padding-left: 8px;
    font-size: 18px;
    font-weight: normal;
  }

  /* img */
  .img-block {
    padding: 5px;
  }

  .insurance-target {
    width: 100%;
  }

  .group-discount {
    width: 100%;
  }

  /* header */
  .header-top-border {
    border-top: 8px solid rgb(0, 79, 138);
  }

  .header-bottom-border {
    border-bottom: 1px solid rgb(0, 79, 138);
  }

  .header-border {
    margin-top: 4px;
    border-bottom: 1px solid #004f8a;
  }

  .header-triangle {
    text-align: center;
    margin-top: -9.5px;
  }

  .header-triangle > div {
    width: 0;
    height: 0;
    display: inline-block;
    border-right: 6px solid transparent;
    border-top: 8px solid #004f8a;
    border-left: 6px solid transparent;
  }
  .line-yellow {
    background: linear-gradient(to top, #efff60 25%, transparent 50%);
  }

  /* footer */

  footer {
    margin-top: 50px;
    border-top: 1px solid #d5d5d5;
  }

  footer > div {
    max-width: 330px;
    position: relative;
    margin: 0px auto;
  }

  /*occupation*/
  .occupation-list {
    border: 4px solid rgb(0, 79, 138);
    padding: 16px;
    background: rgb(242, 238, 232);
    max-width: 880px;
  }

  .occupation-list-header {
    font-size: 20px;
    color: #453c3d;
    font-weight: normal;
    display: block;
  }

  .occupation-list-item {
    display: inline-block;
    line-height: 30px;
    padding: 4px 7px;
    text-align: left;
    border: 1px solid #8a8c93;
    border-radius: 25px;
    background: white;
    color: rgb(0, 79, 138);
  }
  .occupation-list-item-margin {
    display: inline-block;
    margin: 4px;
  }

  .drop-down-birth {
    vertical-align: top;
    display: inline-block;
    width: 25%;
    margin: 0px 4px;
  }

  .drop-down-birth:first-child {
    width: 38%;
  }

  /* plan */
  .plan-board,
  .plan-board-active {
    display: inline-block;
    margin-right: 40px;
    min-width: 640px;
    max-width: 7px;
    border: 1px solid #453c3d;
    border-radius: 4px;
  }

  .plan-board-active {
    border: 1px solid #ff4e00;
  }

  .plan-board:last-child {
    margin-right: 0px;
  }
  .plan-board-info {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
  }
  .plan-board-info > div {
    width: 100%;
  }

  .plan-title-info {
    display: inline-flex;
    width: 580px;
    justify-content: space-between;
    align-items: center;
  }

  .plan-board-amount {
    margin-top: 1.8em;
    border: 2px solid rgba(255, 78, 0, 1);
    border-radius: 5px;
    padding: 0.8em;
  }

  .plan-board-amount > .plan-board-amount-title {
    background-color: rgba(255, 78, 0, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    padding: 0.5em 1em 0em 1em;
    width: fit-content;
    position: relative;
    margin: 0px auto;
    top: -2.3em;
  }

  .plan-board-amount-triangle {
    width: 0;
    height: 0;
    display: block;
    position: relative;
    top: 0.7em;
    left: 4em;
    border-right: 15px solid transparent;
    border-top: 10px solid rgba(255, 78, 0, 1);
    border-left: 15px solid transparent;
  }

  .plan-board-amount > .plan-board-amount-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
  }

  .plan-board-detail {
    padding: 0px 32px 40px 32px;
    background-color: #fff;
    color: black;
    border-radius: 4px;
  }
  .plan-board-detail-price {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    max-width: 640px;
  }

  .plan-board-detail-border {
    padding: 13px 16px 21px 16px;
  }

  .plan-board-detail-price > div:first-child {
    flex: 1;
  }

  .plan-board-detail-price > div:last-child {
    max-width: 50%;
    text-align: right;
  }

  .plan-board-content {
  }

  .plan-board-option {
    background-color: #e2f1fb;
  }

  .plan-board-option > div:last-child {
    padding: 20px;
  }
  .plan-board-option > div:first-child {
    background-color: #44a8ff;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    padding: 10px;
  }

  .plan-board-option > div:first-child::before {
    content: "+";
    font-size: 22px;
    margin-right: 2px;
  }

  .plan-option-check-box {
    display: inline-block;
    border: 2px solid #44a8ff;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    background-color: #fff;
  }
  .plan-option-price {
    display: flex;
  }

  .plan-option-price > div:last-child {
    margin-left: 10px;
    border: 1px solid #453c3d;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 15px;
    min-width: 300px;
    font-size: 14px;
    font-weight: bold;
    color: #453c3d;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .plan-option-price > div:last-child > span:last-child {
    color: #004f8a;
  }

  .plan-background-blue {
    background: #6200ee;
  }
  .plan-background-orange {
    background: #ff8b8b;
  }
  .plan-background-green {
    background: #6faf7c;
  }
  .two-cols {
    display: flex;
    justify-content: space-between;
    max-width: 256px;
  }

  .two-cols > label {
    flex: 1;
  }
  .two-cols > label:first-child {
    margin-right: 10px;
  }

  .board-link {
    text-align: center;
    justify-content: center;
  }

  .background-bage {
    background-color: #f6f6f6;
  }

  .warning {
    color: #8a1b1f;
    font-size: 14px;
    font-weight: normal;
    display: flex;
  }

  .warning > span:first-child {
    margin-right: 3px;
  }

  .base-input-text-width-full,
  .base-input-text-long,
  .base-input-text {
    border: 1px solid #004f8a;
    border-radius: 4px;
    padding-left: 1em;
    height: 3em;
    font-size: 17px;
  }

  .base-input-text-long {
    max-width: 340px;
    width: 25vw;
  }

  .base-input-text:focus {
    border: solid 1px #ff4e00;
    border-radius: 8px;
  }

  .base-input-text-width-full {
    max-width: 360px;
    width: calc(100% - 1em);
  }

  .input-text-two-cols {
    display: flex;
    justify-content: space-between;
    margin: 8px auto;
  }

  .input-text-two-cols > span {
    display: flex;
    align-items: center;
    min-width: 70px;
  }

  .input-items-two-cols {
    display: flex;
  }

  .input-items-two-cols > div:first-child {
    margin-right: 8px;
  }

  .base-input-area > div {
    color: red;
    font-size: small;
  }

  .board-info-text {
    padding: 32px 40px;
    display: flex;
    align-items: flex-start;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .board-info-text > img {
    margin-right: 5px;
  }

  .board-info-text > div {
    color: #264156;
  }

  .board-info-text-with-subTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .board-info-text-with-subTitle > div {
    padding: 0px;
    margin-bottom: 0px;
  }

  .board-info-text-with-subTitle > h4 {
    padding: 0px;
    margin: 0px;
  }

  section {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  @media (min-width: 930px) {
    .displayFlexWide {
      display: flex;
    }
  }

  @media (min-width: 800px) {
    .display-flex-middle {
      display: flex;
    }

    .display-inline-middle {
      display: inline;
    }
  }

  .display-inline {
    display: inline;
  }

  .birthDayArea {
    width: 60%;
    justify-content: space-between;
  }
  .max-width180 {
    max-width: 180px;
  }

  .max-width220 {
    max-width: 220px;
  }

  .max-width264 {
    max-width: 264px;
  }

  .max-width340 {
    max-width: 340px;
  }

  .max-width400 {
    max-width: 400px;
  }

  .max-width640 {
    max-width: 640px;
  }

  .max-width720 {
    max-width: 720px;
  }

  .min-width110 {
    padding-right: 10px;
    min-width: 118px;
  }

  .min-width640 {
    min-width: 640px;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .width240px {
    width: 240px !important;
  }

  .width340px {
    width: 340px !important;
  }

  .width440px {
    width: 440px !important;
  }

  .pc-no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .navigator-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .navigator-page {
    white-space: nowrap;
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    text-align: right;
  }

  .navigator-item {
    height: 40px;
    line-height: 40px;
    padding: 0px 4px 0px 10px;
    display: inline-block;
  }

  .navigator-item-triangle-gray,
  .navigator-item-triangle-blue,
  .navigator-item-triangle {
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 20px solid transparent;
    border-left: 8px solid rgba(255, 136, 0, 1);
    border-bottom: 20px solid transparent;
    margin-right: 4px;
    margin-bottom: -14px;
  }

  .navigator-item-orange {
    background-color: rgba(255, 136, 0, 1);
    padding: 0px 4px;
  }

  .navigator-item-gray {
    background-color: rgba(138, 140, 147, 1);
  }

  .navigator-item-blue {
    background-color: rgba(46, 137, 217, 1);
  }

  .navigator-item-triangle-gray {
    border-left: 8px solid rgba(138, 140, 147, 1);
  }

  .navigator-item-triangle-blue {
    border-left: 8px solid rgba(46, 137, 217, 1);
  }

  .board-table {
    margin-left: 29px;
  }

  .icon-info {
    width: 24px;
    height: 24px;
  }

  .two-col-item-width {
    width: 25vw;
    max-width: 180px;
  }

  .drop-down-card {
    vertical-align: top;
    display: inline-block;
    width: 7em;
    margin: 0px 4px;
  }

  .drop-down-card:first-child {
    width: 4em;
  }
}

/* COMMON */

.displayFlex {
  display: flex;
}

.displayInlineFlex {
  display: inline-flex;
}

.space-between {
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}
.radius20 {
  border-radius: 20px;
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.height2_5em {
  height: 2.5em;
}

h2 {
  font-size: 28px;
}

h4 {
  font-size: 20px;
}

/* Base */
.background-gray {
  background: rgb(244, 244, 244);
}
.visibility-collapse {
  visibility: collapse;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.no-margin {
  margin: 0px !important;
}

.no-mgt {
  margin-top: 0px !important;
}
.mgt8 {
  margin-top: 8px;
}
.mgt12 {
  margin-top: 12px;
}
.mgt24 {
  margin-top: 24px;
}
.mgt36 {
  margin-top: 36px;
}
.mgt40 {
  margin-top: 40px;
}
.mgb8 {
  margin-bottom: 8px;
}
.mgb12 {
  margin-bottom: 12px;
}
.mgb24 {
  margin-bottom: 24px;
}
.mgb40 {
  margin-bottom: 40px;
}
.mgr10 {
  margin-right: 10px;
}
.mgr20 {
  margin-right: 20px;
}
.mgr28 {
  margin-right: 28px;
}
.mgr1em {
  margin-right: 1em;
}
.mgl8 {
  margin-left: 8px;
}
.mgl20 {
  margin-left: 20px;
}

.mgl-auto {
  margin-left: auto;
}

.mgr-auto {
  margin-right: auto;
}

.display-none {
  display: none;
}

.question-mark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  opacity: 1;
  color: rgb(68, 168, 255);
  display: inline-block;
  text-align: center;
  font-size: large;
  margin-right: 4px;
}

.list-style-none {
  list-style: none;
}
/* font */
.font-size-small {
  font-size: small;
}

.font-color-blue {
  color: #004f8a;
}

.font-color-navy {
  color: #264156;
}

.font-color-gray {
  color: rgb(204, 204, 204);
}

.font-color-orange {
  color: #ff4e00;
}

.font-color-white {
  color: white;
}

.font-color-black {
  color: black;
}

.font-color-darkbrown {
  color: #453c3d !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-28 {
  font-size: 28px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height2_5em {
  line-height: 2.5;
}

/* TABLE */

table,
th,
td {
  border: 1px solid #004f8a;
  border-collapse: collapse;
}

th,
td {
  padding: 5px 12px;
  text-align: center;
}

th {
  background-color: #fce4d6;
}

@media (min-width: 640px) {
  .ageArea {
    width: 40%;
  }
}

.ageArea > div {
  align-items: flex-end;
  margin-right: 5px;
}

.ageArea > div > label:first-of-type {
  margin-right: 5px;
}

.link_label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 720px;
  padding-right: 8px;
}

.link_label > div {
  margin-right: 5px;
  background-color: #2e89d9;
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  font-size: 18px;
  line-height: 20px;
}
.link_label > div::after {
  content: "?";
}

.link_label > label {
  font-size: 14px;
  font-weight: normal;
  color: #2e89d9;
  cursor: pointer;
  text-decoration: underline;
}

input[type="text"],
textarea {
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.hr-gray {
  border: 1px solid #d5d5d5;
}

.icon-plus {
  text-align: center;
  margin: 0px auto;
}
.icon-plus::after {
  content: "+";
  color: #fff;
  font-size: 70px;
  font-weight: 900;
}
.icon-reverse-triangle {
  width: 0;
  height: 0;
  display: inline-block;
  border-right: 5px solid transparent;
  border-top: 7px solid #453c3d;
  border-left: 5px solid transparent;
  margin-left: 5px;
  margin-bottom: 2px;
}

.icon-triangle {
  width: 0;
  height: 0;
  display: inline-block;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #004f8a;
  border-left: 5px solid transparent;
  margin-left: 5px;
  margin-bottom: 2px;
}

.icon-selecting::after {
  background-color: #ff4e00;
  color: #fff;
  padding: 3px 10px;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
}

.icon-check::after {
  content: "未選択";
  color: gray;
  font-weight: bold;
  position: relative;
  left: 6px;
  width: 44px;
  height: 25px;
}

.icon-check-checked::after {
  content: "";
  width: 20px;
  height: 8px;
  display: inline-block;
  transform: rotate(135deg);
  margin-left: 12px;
  border-top: 4px solid #ff8800;
  border-right: 4px solid #ff8800;
}

.icon-speech-bubble-checked,
.icon-speech-bubble {
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-speech-bubble-checked > span,
.icon-speech-bubble > span {
  background-color: #ff8800;
  line-height: 25px;
  padding: 2px 4px;
  width: 44px;
  height: 25px;
  color: #fff;
  font-size: 0.1em;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
}

.icon-speech-bubble-checked > span {
  background-color: #4ca852;
}

.icon-speech-bubble-triangle-checked,
.icon-speech-bubble-triangle {
  width: 0;
  height: 0;
  display: inline-block;
  border-right: 6px solid transparent;
  border-top: 8px solid #ff8800;
  border-left: 6px solid transparent;
}
.icon-speech-bubble-triangle-checked {
  content: "";
  border-top: 8px solid #4ca852;
}
.card-icon-container {
  display: flex;
  align-items: center;
}

.card-icon {
  width: 50px;
}

.card-expire-container {
  display: flex;
}

.underline {
  text-decoration: underline;
}

.loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
